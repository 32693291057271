

import App from './App.vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createRouter, createWebHashHistory } from 'vue-router'

import Notifications from '@kyvg/vue3-notification'
import startSeitn from "/src/components/startSeitn/startSeitn.vue";
import streamBingo from "/src/components/streamBingo/streamBingo.vue";
import peekdex from "/src/components/peekmonList/peekmonList.vue";
import streamToys from "/src/components/streamToys/streamToys.vue";
import streamToysSoundBoard from "/src/components/streamToys/streamToysSoundBoard.vue";
import streamToysBongoCat from "/src/components/streamToys/streamToysBongoCat.vue";
import streamToysPoll from "/src/components/streamToys/streamToysPoll.vue";
import streamToysHub from "/src/components/streamToys/streamToysHub.vue";
import streamToysQuote from "/src/components/streamToys/streamToysQuote.vue";
import streamToysDVD from "/src/components/streamToys/streamToysDVD.vue";
import streamToysTimer from "/src/components/streamToys/streamToysTimer.vue";
import docsReader from "/src/components/docsReader/docsReader.vue";
import streamToysList from "/src/components/streamToys/streamToysList.vue";
import editSettings from "/src/components/editSettings/editSettings.vue";
const routes = [
  { path: '/', component: startSeitn, meta: { label: "Startseitn" } },
  { path: '/bingo', component: streamBingo, meta: { label: "Bingo" } },
  { path: '/peekdex', component: peekdex, meta: { label: "Peekdex" } },
  {
    path: '/docs', component: docsReader, meta: { label: "Dokumentation", topic: "docs" }, "children":
      [{ path: 'bongo', component: docsReader, meta: { label: "BongoCat Doku", topic: "bongo", display: "hidden" } }]
  },
  {
    path: '/streamtoys',
    component: streamToys,
    meta: { label: "Streamtoys" },
    children: [
      { path: '', component: streamToysList, meta: { label: "Streamtoys", display: "hidden" } },
      { path: 'soundboard', component: streamToysSoundBoard, meta: { label: "Das Soundboard", display: "hidden", module: "sb" } },
      { path: 'poll', component: streamToysPoll, meta: { label: "Bewertungsoverlay", display: "hidden", module: "poll" } },
      { path: 'bc', component: streamToysBongoCat, meta: { label: "Bongocat", display: "hidden", module: "bc" } },
      { path: 'hub', component: streamToysHub, meta: { label: "Hub Logogenerator", display: "hidden", module: "ph" } },
      { path: 'quote', component: streamToysQuote, meta: { label: "inspirierende Zitate Overlay", display: "hidden", module: "quote" } },
      { path: 'dvd', component: streamToysDVD, meta: { label: "DVD Screensaver", display: "hidden", module: "dvd" } },
      { path: 'timer', component: streamToysTimer, meta: { label: "Timer", display: "hidden", module: "timer" } }

    ],
  },
  { path: '/settings', component: editSettings, meta: { label: "Einstellungen",} },
  { path: '/modview', component: editSettings, meta: { label: "Mod Ansicht",display:"mod"} }



]

const app = createApp(App)
const pinia = createPinia()
const router = createRouter({
  // Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})


app.use(pinia)
app.use(router)
app.use(Notifications)
app.mount('#app')

