<template>
  <peekmonFilter :filteredPeekmons="filteredPeekmons" />
  <div class="peekmonList" :class="'id' + detailUserID">
    <peekmonListEntry
      v-for="daten in filteredPeekmons"
      :key="daten.id"
      :datensatz="daten"
      :loggedIn="status"
      :setInfos="setInfos"
      @changeDetailView="changeDetailView"
    />
  </div>
  <div class="peekmonDetails">
    <peekmonListEntryDetails
      :detailUserID="detailUserID"
      @changeDetailView="changeDetailView"
    />
  </div>
</template>
<script setup>
import { usePeekmonStore } from "@/store/peekmon.js";
import peekmonListEntry from "./peekmonListEntry.vue";
import peekmonFilter from "./peekmonFilter.vue";
import peekmonListEntryDetails from "./peekmonListEntryDetails.vue";

import { useStatusStore } from "@/store/status.js";
import { watch, ref, computed } from "vue";

const detailUserID = ref("0");

const filteredPeekmons = computed(() => {
  let filteredStore = store.peekdex.data;
  if (store.filters.shiny == 1) {
    filteredStore = filteredStore.filter(($value) => $value.shinycount == 0);
  }
  if (store.filters.shiny == 3) {
    filteredStore = filteredStore.filter(($value) => $value.shinycount > 0);
  }

  if (store.filters.caught == 1) {
    filteredStore = filteredStore.filter(($value) => $value.counter == 0);
  }
  if (store.filters.caught == 3) {
    filteredStore = filteredStore.filter(($value) => $value.counter > 0);
  }
  if (store.filters.search != "") {
    filteredStore = filteredStore.filter(
      ($value) =>
        $value.loginName
          .toLowerCase()
          .includes(store.filters.search.toLowerCase()) ||
        $value.username
          .toLowerCase()
          .includes(store.filters.search.toLowerCase())
    );
  }
  return filteredStore;
});
function changeDetailView(userid) {
  if (detailUserID.value != 0) {
    detailUserID.value = "0";
    return;
  }
  detailUserID.value = userid;
}

watch(
  () => detailUserID.value,
  () => {
    if (detailUserID.value != "0") {
      status.registerBodyScroll("peekmonDetails");
    } else {
      status.unregisterBodyScroll("peekmonDetails");
    }
  }
);

const status = useStatusStore();

const store = usePeekmonStore();
console.log(store);
async function fetchData() {
  store.getData();
}
function setInfos(data) {
  return;
}
fetchData();
</script>

<style>
.desktopView .peekmonList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 4px;
  width: calc(100% - 10px);
  padding: 0px 5px;
}
</style>