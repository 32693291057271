<template>
  <div>
      Chatbefehle:<br />
      <span class="commandList"
        v-for="(command, index) in datensatz"
        :key="index"
        @click="copyToClipboard(command.name)"
        ><span class="commandName">{{ command.name }}</span
        >{{ command.value }}<br
      /></span>
    </div>
</template>

<script setup>
import {defineProps} from "vue";

import { useNotification } from "@kyvg/vue3-notification";

const { notify } = useNotification();
function copyToClipboard(value) {
  navigator.clipboard.writeText(value);

  notify({
    text: "In die Zwischenablage kopiert",
    type: "normal",
    duration: 3000,
  });
}
const props = defineProps({
  datensatz: Object,
});
</script>
<style scoped>
.commandList > span {
  display: inline-block;
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid black;
  padding: 2px 4px;
  border-radius: 7px;
  color: blacK;
  margin-right: 5px;
}
.commandList {
  display: block;
  margin: 0.5em 0 20px 0;
}
</style>