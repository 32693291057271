import { defineStore } from 'pinia'

// You can name the return value of `defineStore()` anything you want, 
// but it's best to use the name of the store and surround it with `use` 
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const usePeekmonStore = defineStore('peekmon', {
    state: () => ({ peekdex: [] , filters: {
        caught: 2,
        shiny: 2,
        search: ""
    }}),
    actions: {
        async getData() {
            this.peekdex = []
            const res = await fetch(`https://jvpeek.de/c/api/peekmonList.php`)
            // const res = await fetch(`http://localhost:5000/api/peekmonList.php`)
            this.peekdex = await res.json()
        }
    }
})
export const usePeekmonDetailStore = defineStore('peekmonDetail', {
    state: () => ({ peekmonDetails: {} }),
    actions: {
        async getData(peekmonID) {
            this.peekmonDetails[peekmonID] = []
            const res = await fetch("https://jvpeek.de/c/api/peekmonDetails.php?peekmonID=" + peekmonID)
            // const res = await fetch("http://localhost:5000/api/peekmonDetails.php?peekmonID=" + peekmonID)
            this.peekmonDetails[peekmonID] = await res.json()
            console.log(this.peekmonDetails);
            return;
        }
    },
    getters: {
        getPeekmonByID: (state) => {
            
            return (peekmonID) => { console.log("getter", state.peekmonDetails); return state.peekmonDetails[peekmonID] }
        },
    }
})

