<template>
  <notifications  position="top center" />

  <popupBackdrop />
  <div id="appContainer" :class="sizeClass">
    <navigationBar />
    <contentWrapper />
    
  </div>
</template>
<script setup>
import { watch, onMounted, ref, onBeforeUnmount } from "vue";
import { useStatusStore } from "@/store/status.js";
import navigationBar from "./components/ui/navigationBar.vue";
import contentWrapper from "./components/contentWrapper.vue";
import popupBackdrop from  "./components/ui/popupBackdrop.vue";
const store = useStatusStore();
console.log(navigationBar);
console.log(store);
let sizeClass = ref("desktop");
function handleResize() {
  sizeClass.value = window.matchMedia("(max-width: 600px)").matches
    ? "mobileView"
    : "desktopView";
}
watch(
  () => store.bodyScroll.length,
  () => {
    console.log(store.bodyScroll.length, "bodyscroll hat sich geändert");
    if (store.bodyScroll.length > 0) {
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }
  }
);

watch(
  () => store.loginStatus.wallpaper,
  () => {
    if (store.loginStatus.wallpaper === undefined) {
      return;
    }
    console.log(store.loginStatus.wallpaper);
    document.body.style.setProperty("--wallpaper", "url(\"/c/wallpapers/" + store.loginStatus.wallpaper + "\")");
  }
);
let refreshInterval;
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
  refreshInterval = window.setInterval(store.getLoginStatus, 10000);
  screen.orientation.lock("portrait");
});

onBeforeUnmount(() => {
  clearInterval(refreshInterval);
});
</script>
 
<style>
/* width */
::-webkit-scrollbar {
  width: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#app {
  -webkit-font-smoothing: antialiased;
}
body {
  --wallpaper: url("@/assets/wallpapers/default.jpg");
  padding: 0px;
  margin: 0px;
  background-image: var(--wallpaper);
  background-position: center center;
  background-size: cover;
  background-color: black;
  background-attachment: fixed;
  color: white;
  font-family: "Arial";
}

body.noScroll {
  overflow: hidden;
  padding-right: 4px;
  
}
body .popup {
  z-index: 2;
}

.vue-notification.normal {
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(7px);
  margin-top: 20px;
  border-left: #fff 5px solid;
}
/*
TODO farben vom wallpaper pushen und als globale css vars einbinden
*/

</style>
