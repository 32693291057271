<template>
  <div class="filters">
    <triStateRadio
      name="caught"
      :states="3"
      label="Gefangen"
      @newValue="store.filters.caught = $event"
    />

    <triStateRadio
      name="shiny"
      :states="3"
      label="Shiny"
      @newValue="store.filters.shiny = $event"
    />
    <label>
      <input
        type="text"
        name="search"
        :value="store.filters.search"
        @input="store.filters.search = $event.target.value"
      />
    </label>
    <label class="count">
      <span v-show="props.filteredPeekmons.length!=store.peekdex.data.length">

        {{ props.filteredPeekmons.length }} /
        {{ store.peekdex.data.length }}
      </span>
    </label>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

import triStateRadio from "../ui/triStateRadio.vue";
import { usePeekmonStore } from "@/store/peekmon.js";

const props = defineProps({
  filteredPeekmons: Object,
});

const store = usePeekmonStore();
</script>

<style>
.filters {
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.filters label {
  display: block;
  padding: 20px;
}
.filters label.count {
  width: 6rem;
}

</style>