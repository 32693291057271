<template>
<router-view>
</router-view>

</template>
<script setup>
</script>
<style scoped>
pre {
  background: rgba(0, 0, 0, 0.5);
}
</style>