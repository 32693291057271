<template>
  <svg width="32.000011" height="32" viewBox="0 0 8.4666695 8.4666667">
    <g
      inkscape:label="Layer 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(-281.66763,3.5342292)"
    ><circle
        style="
          fill: #000000;
          fill-opacity: 0.387072;
          stroke-width: 1.45407;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
        id="path929"
        cx="285.90097"
        cy="0.69910389"
        r="4.1142192"
      />
      <path
        style="fill: #ffffff; stroke-width: 0.035431"
        d="m 285.35634,4.909586 c -1.22377,-0.1695447 -2.31479,-0.8485424 -2.98052,-1.8549238 -0.50167,-0.7583632 -0.70819,-1.4458137 -0.70819,-2.35735952 0,-0.91154563 0.20652,-1.59899601 0.70819,-2.35735938 0.61234,-0.9256785 1.58067,-1.5721782 2.7099,-1.8092517 0.4123,-0.086561 1.21818,-0.086561 1.63049,0 1.12923,0.2370735 2.09756,0.8835732 2.7099,1.8092517 0.50167,0.75836337 0.70819,1.44581375 0.70819,2.35735938 0,0.75510012 -0.13462,1.31791142 -0.46764,1.95514672 -0.59575,1.1399592 -1.66805,1.9465354 -2.93273,2.2059809 -0.34557,0.070893 -1.0471,0.096944 -1.37759,0.051156 z m 1.15747,-0.369287 c 1.69276,-0.2949506 2.9379,-1.5380769 3.23276,-3.2275342 0.24659,-1.41290152 -0.38824,-2.9323941 -1.57709,-3.7748252 -0.85242,-0.6040314 -1.90498,-0.8537617 -2.88137,-0.6836331 -0.36208,0.063089 -0.82639,0.2051149 -1.07081,0.3275443 -1.16523,0.5836541 -1.93877,1.6212727 -2.16194,2.89998977 -0.17084,0.97885583 0.0783,2.02927383 0.68365,2.88211743 0.84206,1.1863793 2.36439,1.8220959 3.7748,1.576341 z M 284.3768,1.8790753 c -0.43863,-0.5166874 -0.83283,-0.98378211 -0.876,-1.0379884 l -0.0785,-0.0985573 0.13454,-0.10802952 0.13454,-0.10802989 0.73717,0.86530991 c 0.40545,0.4759205 0.74869,0.8724239 0.76277,0.8811195 0.0141,0.0087 0.6773,-0.6769428 1.47382,-1.52364018 0.79651,-0.84669697 1.4577,-1.53944929 1.46931,-1.53944929 0.0116,0 0.0717,0.0521576 0.13353,0.11590582 l 0.11243,0.11590582 -1.58299,1.68148103 c -0.87064,0.9248147 -1.59201,1.6846138 -1.60305,1.6884423 -0.011,0.00384 -0.37894,-0.4157829 -0.81759,-0.9324698 z"
        id="path932"
      />
    </g>
  </svg>
</template>