<template>
  <div class="entry">
    <h3>
      <a class="anchor" :id="'entry' + datensatz.key"></a>{{ datensatz.name }}
    </h3>

    <component
      v-for="(object, key) in datensatz.objects"
      :key="key"
      :is="objects[object.type]"
      :datensatz="object.content"
      :title="object.content"
    ></component>

  </div>
  <br />
</template>

<script setup>
import { defineProps } from "vue";

import docsReaderComponentText from "./docsReaderComponenttext.vue";
import docsReaderComponentCommands from "./docsReaderComponentcommands.vue";
import docsReaderComponentlinks from "./docsReaderComponentlinks.vue";

const objects = {
  text: docsReaderComponentText,
  commands: docsReaderComponentCommands,
  links: docsReaderComponentlinks
};

const props = defineProps({
  datensatz: Object,
});
</script>
<style scoped>
.entry {
  background: rgba(0, 0, 0, 0.5);
  padding: 1px 20px 1em;
}
</style>