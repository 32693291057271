<template>
  <div class="wizard">
    <tabList v-model="activeStep" :steps="steps" />
    <configSteps>
      <stepTab
        name="welcome"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        <h2>Willkommen!!1!11eins</h2>
        <div>Ich bin der DVD Overlay wizard</div>
      </stepTab>

      <stepTab
        name="channelname"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        <p>Bitte gib hier deinen Twitch-Usernamen ein.<br /></p>
        <inputText
          label="Dein Twitch Username"
          v-model="paramList.channel.value"
        />
      </stepTab>
      <stepTab
        name="timings"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        <inputSlider
          label="Logo Skalierung"
          v-model="paramList.logoScale.value"
          min="20"
          max="200"
          step="10"
          unit="%"
        />

        <inputSlider
          label="Einfluss der Chateingaben"
          v-model="paramList.inputWeight.value"
          min=".1"
          max="3"
          step=".1"
          unit="px"
        />
        <inputSlider
          label="Zeit in der eine Ecke als getroffen gilt"
          v-model="paramList.bounceTime.value"
          min="50"
          max="500"
          step="25"
          unit="ms"
        />
      </stepTab>
      <stepTab name="display" :activeStep="Object.entries(steps)[activeStep][0]">
        <inputDropDown
          label="Logo"
          v-model="paramList.logo.value"
          :options="{
            dvd: 'DVD Logo',
            vhs: 'VHS Logo',
            energystar: 'Energy Star Logo',
          }"
        />
        <inputSlider
          label="Hintergrund abdunkeln"
          v-model="paramList.darken.value"
          min="0"
          max="100"
          step="5"
          unit="%"
        />
      </stepTab>

      <stepTab name="finish" :activeStep="Object.entries(steps)[activeStep][0]">
        So, fertsch. Haste gut gemacht. Jetzt nimm die URL hier und lege eine
        neue Browserquelle in OBS an. Stelle die Breite auf 1920 und die Höhe
        auf 1080 ein. Trage im Feld URL bitte die kopierte Adresse ein.<br />
        <br />
        <urlBar :url="url" /><br />
        Dein Chat kann die Richtung des Logos mit !up, !down, !left und !right
        beeinflussen.
      </stepTab>
    </configSteps>
    <prevNextButtons
      v-model="activeStep"
      :maxPages="Object.entries(steps).length"
    />
  </div>
</template>
<script setup>
// store für userdaten anlegen
// einzelne ui komponenten anlegen
// template schreiben
// bindings setzen (ref() kram)
import { ref, computed, watch } from "vue";
import inputText from "./ui/inputText.vue";
import inputSlider from "./ui/inputSlider.vue";
import prevNextButtons from "./ui/prevNextButtons.vue";
import urlBar from "./ui/urlBar.vue";
import stepTab from "./ui/stepTab.vue";
import tabList from "./ui/tabList.vue";
import inputDropDown from "./ui/inputDropDown.vue";
import wizardStyle from "./ui/wizardStyle.vue";
import configSteps from "./ui/configSteps.vue";
const debug = false;

const activeStep = ref(0);

const steps = {
  welcome: "Willkommen",
  channelname: "Kanalname",
  timings: "Zeiten",
  display: "Anzeige",
  finish: "In OBS einbinden",
};

const paramDefaults = {
  channel: "jvpeek",
  logoScale: 100,
  inputWeight: 0.1,
  bounceTime: 50,
  logo: "dvd",
  darken: 0
};

const paramList = {
  channel: ref(paramDefaults.channel),
  logoScale: ref(100),
  inputWeight: ref(0.1),
  bounceTime: ref(50),
  logo: ref("dvd"),
  darken: ref(0)
};
const baseURL = "https://jvpeek.de/ext/dvd/?";
const url = ref(baseURL);
console.log(paramList);
watch(
  () => Object.entries(paramList),
  (newVal, oldVal) => {
    recalculateParams();
  },
  { deep: true }
);

function recalculateParams() {
  console.log("recalculating parameters");
  let newParams = new URLSearchParams();
  for (const [key, value] of Object.entries(paramList)) {
    if (paramList[key].value != paramDefaults[key] || debug) {
      newParams.append(key, paramList[key].value);
    }
  }
  url.value = baseURL + newParams.toString();
}
recalculateParams();
</script>
<style scoped>
</style>
  