<template>
  <form>
    <label :for="name">
      {{ label }}

      <input
        type="range"
        class="pillSlider"
        :id="name"
        min="1"
        :max="states"
        :value="thisValue"
        :style="'width:' + states * 1.5 + 'em;'"
        @input="(event) => setValue(event.target.value)"
        :class="
          (thisValue == 1 ? 'first' : '') + (thisValue == states ? 'last' : '')
        "
      />
    </label>
    <!-- <input
      v-for="n in states"
      :value="n"
      type="radio"
      class="radioOption"
      :class="(n == 1 ? 'first' : '') + (n == states ? 'last' : '')"
      :name="name"
      :key="n"
    /> -->
  </form>
</template>
  
  <script setup>
import { defineProps, ref, defineEmits } from "vue";
const $emit = defineEmits(["newValue"]);

const props = defineProps({
  name: String,
  states: Number,
  label: String,
});
const thisValue = ref(Math.round(props.states / 2));
function setValue(value) {
  thisValue.value = value;
  $emit("newValue", value);
}
</script>
  
<style scoped>
.pillSlider {
  --background: rgba(0,0,0,.5);
}
.pillSlider.last {
    --background: rgba(0,255,0,.5);
}
.pillSlider.first {
    --background: rgba(255,0,0,.5);
}
input[type=range].pillSlider {
  width: 100%;
  margin: 0px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type=range].pillSlider:focus {
  outline: none;
}
input[type=range].pillSlider::-webkit-slider-runnable-track {
  background: var(--background);
  border: 0;
  border-radius: 50cqh;
  width: 100%;
  height: 1.5em;
  cursor: pointer;
}
input[type=range].pillSlider::-webkit-slider-thumb {
  margin-top: 0px;
  width: 1.5em;
  height: 1.5em;
  background: #ffffff;
  border: 0;
  border-radius: 50cqh;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type=range].pillSlider:focus::-webkit-slider-runnable-track {
  background: var(--background);
}
input[type=range].pillSlider::-moz-range-track {
  background: var(--background);
  border: 0;
  border-radius: 50cqh;

  width: 100%;
  height: 1.5em;
  cursor: pointer;
}
input[type=range].pillSlider::-moz-range-thumb {
  width: 1.5em;
  height: 1.5em;
  background: #ffffff;
  border: 0;
  border-radius: 50cqh;
  cursor: pointer;
}
input[type=range].pillSlider::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 1px 0;
  color: transparent;
  width: 100%;
  height: 1.5em;
  cursor: pointer;
}
input[type=range].pillSlider::-ms-fill-lower {
  background: var(--background);
  border: 0;
  border-radius: 50cqh;
}
input[type=range].pillSlider::-ms-fill-upper {
  background: var(--background);
  border: 0;
  border-radius: 50cqh;
}
input[type=range].pillSlider::-ms-thumb {
  width: 1.5em;
  height: 1.5em;
  background: #ffffff;
  border: 0;
  border-radius: 50cqh;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type=range].pillSlider:focus::-ms-fill-lower {
  background: var(--background);
}
input[type=range].pillSlider:focus::-ms-fill-upper {
  background: var(--background);
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range].pillSlider {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

</style>