import { defineStore } from 'pinia'
import { ofetch } from 'ofetch'
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification();
//TODO Daten neu holen, wenn loginstatus geändert
const solutions = [
    [0, 1, 2, 3, 4],
    [5, 6, 7, 8, 9],
    [10, 11, 13, 14],
    [15, 16, 17, 18, 19],
    [20, 21, 22, 23, 24],
    [0, 5, 10, 15, 20],
    [1, 6, 11, 16, 21],
    [2, 7, 17, 22],
    [3, 8, 13, 18, 23],
    [4, 9, 14, 19, 24],
    [0, 6, 18, 24],
    [4, 8, 16, 20]
];
export const useBingoStore = defineStore('bingo', {
    state: () => ({ bingoBoard: [], bingoBase: [] , submitState: {}}),
    actions: {
        async getData() {
            this.bingoBase = []
            const res = await fetch(`https://jvpeek.de/c/api/bingo.php`)
            this.bingoBase = await res.json()
        },
        async setData(field, index) {
            if (!field.key) {
                return;
            }
            field.selected = !field.verified;
            field.requested = true;
            const res = await ofetch('https://jvpeek.de/c/api/bingo.php', { parseResponse: JSON.parse, method: 'POST', body: this.bingoBase.myBoard[index] })
            this.bingoBase = await res;
        },
        async submitData() {
            const response = confirm("Bingofeld einreichen?");
            if (!response) {
                return;
            }
            const res = await fetch(`https://jvpeek.de/c/api/submitBingo.php`)
            notify({
                text: "Bingofeld wurde eingereicht",
                type: "normal",
                duration: 10000,
              });
            this.submitState = await res.json()
        }
    },
    getters: {
        
        solved(state) { return solutions.some(group => group.every(field => state.bingoBase?.myBoard[field].verified)) }
    }
})