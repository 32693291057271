<template>
  
  <div id="burgerMenuButton" @click="toggleMenu"><menuIcon /></div>
  <div
    v-show="showMenu"
    class="popup"
    id="burgerMenu"
    @mousemove="calculateShadowBums"
  
  >
  
    <ul>
      <li v-for="(route, key) in router.getRoutes()" :key="key">
        <router-link v-if="isVisible(route)" @click="toggleMenu()" :to="route.path"
          >{{ route.meta.label }}</router-link
        >
      </li>
    </ul>
  </div>
</template>
<script setup>
import { ref } from "vue";
import menuIcon from "/src/components/ui/icons/menuIcon.vue";
import { useStatusStore } from "@/store/status";
import { useRouter } from "vue-router";

const store = useStatusStore();
const showMenu = ref(false);
const router = useRouter();
console.log();
function isVisible(route) {
  if (route.meta.display == 'hidden') {
    return false;
  }
  if (route.meta.display == 'mod' && !store.loginStatus.mod) {
    return false;
  }
  
  return true;
}
function calculateShadowBums(event) {
  document.querySelectorAll("#burgerMenu ul>li").forEach((element) => {
    let thisX = event.layerX - element.offsetLeft + "px";
    let thisY = event.layerY - element.offsetTop + "px";

    element.style.setProperty("--posX", thisX);
    element.style.setProperty("--posY", thisY);
  });
}

function toggleMenu() {
  showMenu.value = !showMenu.value;
  if (showMenu.value) {
    store.registerBodyScroll("burgerMenu");
  } else {
    store.unregisterBodyScroll("burgerMenu");
  }
}
</script>
  
<style scoped>
#burgerMenuButton {
  position: absolute;
  top: 50%;
  transform: translate(10px, -50%);
}
#burgerMenu {
  width: 100%;
  height: calc(100vh - 70px);
  overflow: auto;
  background: #222;

  position: absolute;
  top: 70px;
  left: 0px;
}
.desktopView #burgerMenu {
  width: 600px;
}

#burgerMenu ul {
  list-style: none;
  padding: 0px;
  --posX: -1000px;
  --posY: 0px;

  position: relative;
}
#burgerMenu ul > li {
  margin: 2px 0px;
  line-height: 3em;
  font-size: 1em;
  padding-left: 10px;
  cursor: pointer;
  /* TODO Problem für Zukunfts-Jan /*
  /* background-image: radial-gradient(
    circle at var(--posX) var(--posY),
    rgba(255, 255, 255, 0.2) 1%,
    rgba(255, 255, 255, 0) 50%
  ); */
}
/* #burgerMenu ul > li::before {
  content: "";
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  position: absolute;
  

  background: black;
} */
#burgerMenu ul > li > a {
  text-decoration: none;
  color: white;
}
</style>