<template>
  
<svg
   width="64"
   height="47.4366"
   viewBox="0 0 16.933333 12.550934"
   version="1.1"
   id="svg5"

   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">

  <defs
     id="defs2" />
  <g
     inkscape:label="Layer 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(-77.412384,-53.43465)">
    <path
       style="fill:#ffffff;stroke-width:1.47848;stroke-linecap:round;stroke-linejoin:round"
       id="path2886"
       d="m 80.626174,57.698018 c 0.06261,-0.01784 0.125125,-0.03922 0.187832,-0.05376 0.119158,-0.02764 0.238483,-0.04828 0.357717,-0.07274 0.18476,-0.03792 0.369492,-0.07708 0.554266,-0.114399 0.435368,-0.08792 1.003578,-0.201037 1.435566,-0.272705 0.240544,-0.0399 0.481229,-0.07169 0.721843,-0.107532 1.222522,-0.190994 2.446796,-0.196024 3.670552,-0.224759 0.609824,0.0068 1.220164,-0.01998 1.829661,0.05689 0.08129,0.01028 0.162483,0.02611 0.243724,0.03918 0,0 0.326475,-0.711752 0.326475,-0.711752 v 0 c -0.306307,0.01299 -0.612928,0.0095 -0.919145,0.03603 -0.32553,0.02815 -0.475505,0.06405 -0.8084,0.116842 -0.165396,0.02622 -0.330828,0.05041 -0.496242,0.07561 -0.185503,0.02149 -0.370962,0.04684 -0.556511,0.06445 -0.834894,0.07931 -0.366515,0.01591 -1.218426,0.07609 -0.550859,0.03887 -0.738882,0.06533 -1.293503,0.159164 -0.231611,0.0392 -0.463094,0.08499 -0.694641,0.127491 -0.593129,0.09225 -0.840067,0.151658 -1.431432,0.161162 -0.449397,0.0072 -0.899688,-0.03003 -1.344838,-0.229141 -0.0437,-0.01955 -0.0867,-0.05125 -0.130043,-0.07689 0,0 -0.434455,0.950763 -0.434455,0.950763 z" />
    <path
       style="fill:#ffffff;stroke-width:1.38072;stroke-linecap:round;stroke-linejoin:round"
       id="path2894"
       d="m 81.044877,60.184916 c 0.622633,-0.122606 1.245576,-0.243954 1.869068,-0.333517 0.279291,-0.04012 0.558779,-0.07008 0.83817,-0.105132 0.678854,-0.07252 1.358311,-0.09131 2.037647,-0.08434 0.373369,0.0039 0.746635,0.02369 1.12,0.02857 0.115429,-0.0064 0.23086,-0.01252 0.346285,-0.01932 0.317934,-0.01871 0.332288,-0.0248 0.636097,-0.03062 0.206666,-0.0039 0.413049,-0.0016 0.619685,-0.01319 0.199024,-0.01724 0.398133,-0.0091 0.596962,0.01692 0,0 0.419643,-0.803411 0.419643,-0.803411 v 0 c -0.219073,0.03989 -0.438275,0.07477 -0.657743,0.0961 -0.06491,0.0093 -0.129876,0.01624 -0.194737,0.02773 -0.739696,0.130708 0.376854,-0.05056 -0.484004,0.09953 -0.280632,0.04894 -0.369725,0.05545 -0.656407,0.07157 -0.114428,0.0064 -0.228882,0.0091 -0.343323,0.01387 -0.116515,0.0115 -0.233114,0.01805 -0.349546,0.03434 -0.306259,0.04285 -0.589168,0.112456 -0.896461,0.176881 -0.716993,0.150332 -1.434702,0.291608 -2.154392,0.309393 -0.615855,-0.0056 -0.834288,0.01692 -1.425451,-0.07353 -0.150415,-0.02301 -0.300462,-0.0613 -0.450364,-0.101174 -0.09153,-0.02436 -0.268016,-0.08328 -0.376368,-0.142145 -0.01429,-0.0078 -0.028,-0.02165 -0.042,-0.03248 0,0 -0.452761,0.864124 -0.452761,0.864124 z" />
    <path
       style="fill:#ffffff;stroke-width:1.48566;stroke-linecap:round;stroke-linejoin:round"
       id="path2896"
       d="m 81.25954,62.201759 c 0.290255,0.02432 0.580659,0.0292 0.870976,0.04303 0.176255,0.0084 0.352481,0.02293 0.52875,0.02777 0.02909,7.73e-4 1.177418,0.0059 1.205209,0.0061 0.202503,-0.0033 0.405024,-9.02e-4 0.607507,-0.01 2.189998,-0.09851 -0.923546,-4.27e-4 1.517854,-0.0953 0.558976,-0.02172 0.994541,-0.02439 1.548758,8.37e-4 0.197963,0.009 0.395861,0.02808 0.593792,0.04213 0.417398,0.04234 0.834731,0.08913 1.251873,0.150509 0,0 0.351797,-0.782677 0.351797,-0.782677 v 0 c -0.488702,0.146083 -0.97887,0.238352 -1.468885,0.333444 -0.566661,0.08335 -0.836915,0.138504 -1.419598,0.155151 -0.268624,0.0076 -0.537298,3.06e-4 -0.805893,-0.01371 -0.253359,-0.01332 -0.506543,-0.04873 -0.759858,-0.06841 -0.717931,-0.0558 -0.577848,-0.04271 -1.268825,-0.03955 -0.510553,-0.019 -0.652071,5.1e-4 -1.154617,-0.113141 -0.158594,-0.03586 -0.316944,-0.08367 -0.474772,-0.143067 -0.209602,-0.07889 -0.38394,-0.160948 -0.581779,-0.339931 -0.03121,-0.02822 -0.06024,-0.07517 -0.09036,-0.11273 0,0 -0.451929,0.959748 -0.451929,0.959748 z" />
  </g>
</svg>
</template>