<template>
  <div>
    <input
      type="text"
      :id="randomId"
      :value="modelValue"
      :placeholder="label"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  label: String,
  defaultValue: String,
  maxLength: Number,
  modelValue: String,
});
defineEmits(["update:modelValue"]);

const randomId = "id" + String(Math.floor(Math.random() * 2000000));
</script>
<style scoped>
div {

}
label {
  text-align: right;
  padding-right: 1em;
}
input {
  width: calc(100% - 2px - 1em);
  display: block;
  background: rgba(0,0,0,0);
  border: none;
  border-bottom: 1px solid white;
  margin-bottom: 10px;
  color: white;
  padding: 0.5em .5em;
  font-size: 16px;
}
input:focus {
  background: rgba(255,255,255,.1);

  border-bottom: 2px solid white;
  outline: none;
  margin-bottom: 9px;
}
</style>