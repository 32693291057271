<template>
  <div class="wizard">
    <tabList v-model="activeStep" :steps="steps" />
    <configSteps>
      <stepTab
        name="welcome"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        
        <div>Ich bin der Poll wizard</div>
      </stepTab>

      <stepTab
        name="channelname"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
      <p>Bitte gib hier deinen Twitch-Usernamen ein.<br /></p>
        <inputText
          label="Dein Twitch Username"
          v-model="paramList.channel.value"
        />
      </stepTab>
      <stepTab
        name="voteOptions"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        <inputSlider
          label="Minimum"
          v-model="paramList.minVotes.value"
          min="0"
          max="10"
          :step="paramList.stepSize.value"
        />
        <inputSlider
          label="Maximum"
          v-model="paramList.maxVotes.value"
          min="0"
          max="10"
          :step="paramList.stepSize.value"
        />

        <inputSlider
          label="Abstufung"
          v-model="paramList.stepSize.value"
          min=".5"
          max="2"
          step="0.5"
        />

        <span>Abstimmungspunkte insgesamt:
        {{ totalSteps }}</span><br /><br />
        <inputSlider
          label="Dauer in Sekunden"
          v-model="paramList.timer.value"
          min="0"
          max="300"
          step="1"
        />
        <inputDropDown
          label="Wo soll gesucht werden?"
          v-model="paramList.filter.value"
          :options="{
            any: 'Überall',
            begin: 'Anfang',
            end: 'Ende',
            single: 'Einzeln',
          }"
        ></inputDropDown>
      </stepTab>

      <stepTab name="labels" :activeStep="Object.entries(steps)[activeStep][0]">
        <inputText label="Headertext" v-model="paramList.headerText.value" />
        <br />
        <inputText
          v-for="index in totalSteps"
          :key="'label' + index"
          :label="
            'Label ' +
            ((index - 1) * paramList.stepSize.value +
              parseFloat(paramList.minVotes.value)).toFixed(1)
          "
          v-model="paramList.labels.value[index - 1]"
        />
      </stepTab>

      <stepTab name="finish" :activeStep="Object.entries(steps)[activeStep][0]">
        So, fertsch. Haste gut gemacht. Jetzt nimm die URL hier und lege eine
        neue Browserquelle in OBS an. Breite und Höhe kannst du nach Belieben
        auswählen. Trage im Feld URL bitte die kopierte Adresse ein.<br />
        <br />
        Setze außerdem den Haken bei "Browser bei Szenenaktivierung
        aktualisieren"<br />
        <urlBar :url="url"></urlBar>
      </stepTab>
    </configSteps>
    <!-- <previewWindow width="400" height="600" :url="url"></previewWindow>-->
    <prevNextButtons
      v-model="activeStep"
      :maxPages="Object.entries(steps).length"
    />
  </div>
</template>
<script setup>
// store für userdaten anlegen
// einzelne ui komponenten anlegen
// template schreiben
// bindings setzen (ref() kram)
import { ref, computed, watch } from "vue";
import inputText from "./ui/inputText.vue";
import inputSlider from "./ui/inputSlider.vue";
import inputDropDown from "./ui/inputDropDown.vue";
import prevNextButtons from "./ui/prevNextButtons.vue";
import urlBar from "./ui/urlBar.vue";
import stepTab from "./ui/stepTab.vue";
import tabList from "./ui/tabList.vue";
import previewWindow from "./ui/previewWindow.vue";
import wizardStyle from "./ui/wizardStyle.vue";
import configSteps from "./ui/configSteps.vue";
const debug = false;

const activeStep = ref(0);

const steps = {
  welcome: "Willkommen",
  channelname: "Kanalname",
  voteOptions: "Abstimmungsoptionen",
  labels: "Beschriftungen",
  finish: "In OBS einbinden",
};

const paramDefaults = {
  channel: "jvpeek",
  minVotes: 0,
  maxVotes: 10,
  stepSize: 1,
  timer: 0,
  labels: [],
  filter: "any",
  headerText: "",
};

const paramList = {
  channel: ref(paramDefaults.channel),
  minVotes: ref(0),
  maxVotes: ref(10),
  stepSize: ref(1),
  timer: ref(60),
  labels: ref([]),
  filter: ref("any"),
  headerText: ref(""),
};
const baseURL = "https://jvpeek.de/ext/poll/?";
const url = ref(baseURL);
watch(
  [
    paramList.channel,
    paramList.minVotes,
    paramList.maxVotes,
    paramList.stepSize,
    paramList.timer,
    paramList.labels,
    paramList.filter,
    paramList.headerText,
  ],
  () => {
    recalculateParams();
  },
  { deep: true }
);

const totalSteps = computed(() => {
  return parseInt(
    (
      (paramList.maxVotes.value - paramList.minVotes.value) /
        paramList.stepSize.value +
      1
    ).toFixed(0),
    10
  );
});
function recalculateParams() {
  let newParams = new URLSearchParams();
  for (const [key, value] of Object.entries(paramList)) {
    if (paramList[key].value != paramDefaults[key] || debug) {
      if (Array.isArray(paramList[key].value)) {
        newParams.append(
          key,
          paramList[key].value.slice(0, totalSteps.value || 0).join("|")
        );
        continue;
      }
      newParams.append(key, paramList[key].value);
    }
  }
  url.value = baseURL + newParams.toString();
}
recalculateParams();
</script>
<style scoped>
</style>
  