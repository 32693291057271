<template>
  <p>
    Ich entwickle gelegentlich kleine Spielereien, die jeder gerne im eigenen
    Stream nutzen darf.<br />Hier findest du eine kleine Auflistung der
    bisherigen Spielzeuge.<br />Viel Spaß
  </p>
  <div class="tools">
    <div
      class="tool"
      v-for="(
        child, key
      ) in $router.currentRoute.value.matched[0].children.filter(
        (child, key) => child.path !== ''
      )"
      :key="key"
    >
      <a :href="'#' + currentPath + '/' + child.path">{{ child.meta.label }}</a>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const currentPath = ref(router.currentRoute.value.fullPath);
</script>
<style scoped>
p {
  text-align: center;
  padding: 10px;
  margin: 10px 10px 5px;

  background: rgba(0, 0, 0, 0.5);
  border: black 1px solid;
  border-radius: 8px;

  white-space: pre-line;
}
.tools {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 10px;
}
.mobileView .tools {
  grid-template-columns: repeat(1, 1fr);
}
.mobileView .tool a {
  aspect-ratio: 6/1;
}

.tool a {
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  border: black 1px solid;
  border-radius: 8px;
  aspect-ratio: 2/1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 1.2em;
}
</style>