<template>
    
<div class="popupBackdrop"></div>
</template>
<style scoped>
body.popupBackdrop {
  content: "";
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 2s ease-out, backdrop-filter 2s ease-out;
  z-index: -1;
}
body.noScroll .popupBackdrop {
  content: " ";
  z-index: 1;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.8);
  transition: background-color 500ms ease-out, backdrop-filter 500ms ease-out;
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0px;
  left: 0px;
}

</style>