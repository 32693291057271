<template>
  <div class="wizard">
    <tabList v-model="activeStep" :steps="steps" />
    <configSteps>
      <stepTab
        name="welcome"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        <h2>Willkommen!!1!11eins</h2>
        <div>Ich bin der Poll wizard</div>
      </stepTab>

      <stepTab
        name="channelname"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
      <p>Bitte gib hier deinen Twitch-Usernamen ein.<br /></p>
        <inputText
          label="Dein Twitch Username"
          v-model="paramList.channel.value"
        />
      </stepTab>
      <stepTab name="volume" :activeStep="Object.entries(steps)[activeStep][0]">
        <inputSlider
          label="Lautstärke"
          v-model="paramList.volume.value"
          min="0.1"
          max="1"
          step="0.1"
        />
      </stepTab>

      <stepTab name="finish" :activeStep="Object.entries(steps)[activeStep][0]">
        So, fertsch. Haste gut gemacht. Jetzt nimm die URL hier und lege eine
        neue Browserquelle in OBS an. Stelle die Breite auf 1920 und die Höhe auf 1080 ein. Trage im Feld URL bitte die kopierte Adresse ein.<br />
        <br />
        <urlBar :url="url" />
      </stepTab>
      <stepTab
        name="information"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
      <p>Um das Soundboard zu deaktivieren, können Du und deine Moderatoren <pre>!disablesounds</pre> in den Chat eingeben. Um das Soundboard wieder zu aktivieren, nutze den Befehl <pre>!enablesounds</pre>.</p>
      <p>Hier findest Du eine Soundliste. Diese URL kannst du auch in deinen Chatbot einbauen, damit dieser sie an deine User verteilt. <br /><urlBar
          :url="
            'https://jvpeek.de/ext/sb/sounds/soundlist.php?channel=' +
            paramList.channel.value
          "
        /></p>
        
      </stepTab>
    </configSteps>
    <prevNextButtons v-model="activeStep" :maxPages="Object.entries(steps).length" />
  </div>
</template>
<script setup>
// store für userdaten anlegen
// einzelne ui komponenten anlegen
// template schreiben
// bindings setzen (ref() kram)
import { ref, computed, watch } from "vue";
import inputText from "./ui/inputText.vue";
import inputSlider from "./ui/inputSlider.vue";
import prevNextButtons from "./ui/prevNextButtons.vue";
import urlBar from "./ui/urlBar.vue";
import stepTab from "./ui/stepTab.vue";
import tabList from "./ui/tabList.vue";
import previewWindow from "./ui/previewWindow.vue";
import wizardStyle from "./ui/wizardStyle.vue";
import configSteps from "./ui/configSteps.vue";
const debug = false;

const activeStep = ref(0);

const steps = {
  welcome: "Willkommen",
  channelname: "Kanalname",
  volume: "Lautstärke",
  finish: "In OBS einbinden",
  information: "Zusätzliche Infos",
};

const paramDefaults = {
  channel: "jvpeek",
  volume: 1,
};

const paramList = {
  channel: ref(paramDefaults.channel),
  volume: ref(0.7),
};
const baseURL = "https://jvpeek.de/ext/sb/?";
const url = ref(baseURL);
watch(
  [paramList.channel, paramList.volume],
  () => {
    recalculateParams();
  },
  { deep: true }
);

const totalSteps = computed(() => {
  return parseInt(
    (
      (paramList.maxVotes.value - paramList.minVotes.value) /
        paramList.stepSize.value +
      1
    ).toFixed(0),
    10
  );
});
function recalculateParams() {
  let newParams = new URLSearchParams();
  for (const [key, value] of Object.entries(paramList)) {
    if (paramList[key].value != paramDefaults[key] || debug) {
      if (Array.isArray(paramList[key].value)) {
        newParams.append(
          key,
          paramList[key].value.slice(0, totalSteps.value || 0).join("|")
        );
        continue;
      }
      newParams.append(key, paramList[key].value);
    }
  }
  url.value = baseURL + newParams.toString();
}
recalculateParams();
</script>
<style scoped>
</style>
  