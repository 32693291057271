<template>
    <div class="content">
      <router-view></router-view>
    </div>
    
  </template>
  <script setup>
    
  import { useStatusStore } from "@/store/status";
  const store=useStatusStore();

  </script>
  
  <style scoped>
  .content {
    padding-top: 71px;
  }
  </style>