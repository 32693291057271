<template>
  <docsReaderIndex :datensatz="docs.docsTopics" />
  <docsReaderTopic
    v-for="entry in docs.docsTopics"
    :key="entry.key"
    :datensatz="entry"
  />
</template>
  <script setup>
import { useDocsStore } from "@/store/docs";
import { defineProps } from "vue";
import { useRoute } from "vue-router";

import docsReaderIndex from "./docsReaderIndex.vue";
import docsReaderTopic from "./docsReaderTopic.vue";
const docs = useDocsStore();

const route = useRoute();

const props = defineProps({
  datensatz: Object,
});
docs.getData(route.meta.topic).then(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  scroll(urlParams.get("goto"));

});

function scroll(element) {
  element = document.getElementById(element);
  if (!element) {
    return;
  }
  window.scrollTo(element.offsetLeft, element.offsetTop-75);
}
</script>
  <style scoped>
</style>