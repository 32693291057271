<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 8.4666695 8.4666667"
    version="1.1"
    id="svg5"
    inkscape:version="1.1.2 (0a00cf5339, 2022-02-04)"
    sodipodi:docname="star.svg"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <sodipodi:namedview
      id="namedview7"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      showgrid="false"
      fit-margin-top="0"
      fit-margin-left="0"
      fit-margin-right="0"
      fit-margin-bottom="0"
    />
    <defs id="defs2" />
    <g
      inkscape:label="Layer 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(-281.66763,3.5342292)"
    >
      <circle
        style="
          fill: #000000;
          fill-opacity: 0.387072;
          stroke-width: 1.45407;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
        id="path929"
        cx="285.90097"
        cy="0.69910389"
        r="4.1142192"
      />
      <path
        id="path1207"
        style="
          fill: #ffffff;
          stroke-width: 5.00001;
          stroke-linecap: round;
          stroke-linejoin: round;
        "
        d="M 16 0 C 14.848959 0 13.697118 0.082513906 12.917969 0.24609375 C 8.6500128 1.1421196 4.9901372 3.5853569 2.6757812 7.0839844 C 0.77970564 9.9502397 0 12.548929 0 15.994141 C 0 19.439353 0.77970564 22.036089 2.6757812 24.902344 C 5.1919262 28.70599 9.3161338 31.273264 13.941406 31.914062 C 15.190502 32.087121 17.842346 31.988645 19.148438 31.720703 C 23.92833 30.740122 27.980768 27.69132 30.232422 23.382812 C 31.49108 20.974364 32 18.848062 32 15.994141 C 32 12.548929 31.220294 9.9502397 29.324219 7.0839844 C 27.009863 3.5853569 23.349987 1.1421196 19.082031 0.24609375 C 18.302863 0.082513906 17.151041 0 16 0 z M 16.136719 1.2714844 C 19.079118 1.3022598 22.057231 2.2691771 24.574219 4.0527344 C 29.06751 7.236726 31.46715 12.980212 30.535156 18.320312 C 29.420725 24.705663 24.714239 29.402804 18.316406 30.517578 C 12.985723 31.446416 7.2314171 29.0445 4.0488281 24.560547 C 1.7608911 21.337201 0.81914926 17.367581 1.4648438 13.667969 C 2.3083209 8.8350223 5.2326998 4.912968 9.6367188 2.7070312 C 10.560511 2.244306 12.315102 1.7071966 13.683594 1.46875 C 14.490845 1.3280925 15.312847 1.2628672 16.136719 1.2714844 z M 16.085938 4.0976562 L 12.019531 10.488281 L 4.6484375 12.226562 L 9.46875 18.068359 L 8.8457031 25.617188 L 15.890625 22.837891 L 22.876953 25.763672 L 22.410156 18.203125 L 27.351562 12.462891 L 20.017578 10.572266 L 16.085938 4.0976562 z "
        transform="matrix(0.26458333,0,0,0.26458333,281.66763,-3.5342292)"
      />
    </g>
  </svg>
</template>
<script setup></script>
