<template>
  <!-- TODO Auf Dialog umstellen -->
  <div
    v-show="detailUserID != '0'"
    :open="detailUserID != '' ? true : false"
    id="peekmonDetails"
    class="popup"
  >
    <div id="peekmonInfo" :class="'type' + getPeekdexEntry().type">
      <div id="iggsbaddne" @click="$emit('changeDetailView', '0')">
        <img src="@/assets/img/close.png" />
      </div>
      <div class="name">{{ getPeekdexEntry().username }}</div>
      <div
        class="image"
        :style="{ backgroundImage: `url('${getPeekdexEntry().image}') ` }"
      ></div>
      <div id="peekmonSpecs">
        <div class="description">
          {{ getPeekdexEntry().description }}
        </div>

        <div class="spec">
          <label>Typ</label><span>{{ getPeekdexEntry().type }}</span>
        </div>
        <!-- <div class="spec">
        <label>Letzter Raid</label><span>{{ getPeekdexEntry().lastRaid }}</span>
      </div> -->
        <div v-if="hasAttack" class="spec">
          <label>Special Attack</label
          ><span>{{ getPeekdexEntry().specialAttack }}</span>
        </div>
        <div class="spec">
          <label>maximales Level</label
          ><span>{{ getPeekdexEntry().maxLevel }}</span>
        </div>
        <div class="spec">
          <label for="capStat">Fangstatistik</label
          ><meter
            :value="calculateRatio()"
            min="0"
            max="100"
            low="50"
            optimum="100"
            id="capStat"
            :title="
              calculateRatio() +
              '% gefangen.\n\n' +
              getPeekdexEntry().failNum +
              'x nicht gefangen\n' +
              getPeekdexEntry().faintNum +
              'x besiegt worden\n' +
              getPeekdexEntry().catchNum +
              'x gefangen\n'
            "
          ></meter>
        </div>
        <div class="spec">
          <label for="capStat">Twitch Profil</label
          ><a :href="'https://twitch.tv/' + getPeekdexEntry().loginName" target="_blank">
            <twitchLogo color="#FFFFFFaa" dwidth="2em" dheight="2em" />
          </a>
        </div>
      </div>
    </div>
    <inlineLoginButton
      v-if="statusStore.loginStatus.status == 'unauthorized'"
      loginText="Mit Twitch einloggen"
    />

    <div id="peekmonInventory" v-else>
      <span class="inventoryMessage">{{
        getPeekdexEntry().counter > 0
          ? "Dies sind alle " +
            getPeekdexEntry().username +
            ", die du gefangen hast:"
          : "Du hast bisher noch keines dieser Peekmon gefangen."
      }}</span>
      <ul>
        <peekmonInventory
          v-for="datensatz in peekmonData.value.data"
          :key="datensatz.id"
          :datensatz="datensatz"
        />
      </ul>
    </div>
  </div>
</template>

<script setup>
import { useStatusStore } from "@/store/status";
import { usePeekmonStore, usePeekmonDetailStore } from "@/store/peekmon.js";
import { watch, computed, defineProps, reactive } from "vue";
import peekmonInventory from "./peekmonInventory.vue";
import InlineLoginButton from "../ui/inlineLoginButton.vue";
import twitchLogo from "../ui/icons/twitchLogo.vue";

const store = usePeekmonDetailStore();
const peekdex = usePeekmonStore();
const statusStore = useStatusStore();
const props = defineProps({
  detailUserID: String,
});
const hasAttack = computed(() => {
  return getPeekdexEntry().specialAttack ? true : false;
});
const peekmonData = reactive({ loaded: false, value: [] });
watch(
  () => props.detailUserID,
  async () => {
    if (props.detailUserID == "0") {
      return;
    }
    await store.getData(props.detailUserID);
    peekmonData.value = store.getPeekmonByID(props.detailUserID);
    peekmonData.loaded = true;
  }
);
function getPeekdexEntry() {
  if (props.detailUserID == "0") {
    return [];
  }
  let filteredPeekdex = peekdex.peekdex.data?.filter((item) => {
    return item.userid == props.detailUserID;
  });

  return filteredPeekdex[0];
}
function calculateRatio(
  win = getPeekdexEntry().catchNum,
  fail = getPeekdexEntry().failNum,
  faint = getPeekdexEntry().faintNum
) {
  let fails = parseInt(fail, 10) + parseInt(faint, 10);
  let wins = parseInt(win, 10);
  if (isNaN(fails) || isNaN(wins)) {
    return 0;
  }
  let total = fails + wins;
  return Math.floor((wins * 100) / total);
}
</script>
<style scoped>
.peekmon {
  font-family: pkmn;
}
#peekmonInfo {
  overflow: auto;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  position: relative;
}
#peekmonInfo #iggsbaddne {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
#peekmonInfo.typenormal {
  background: url("@/assets/img/type-normal.png") top center no-repeat;
}
#peekmonInfo.typehardware {
  background: url("@/assets/img/type-hardware.png") top center no-repeat;
}
#peekmonInfo.typetroll {
  background: url("@/assets/img/type-troll.png") top center no-repeat;
}
#peekmonInfo.typecode {
  background: url("@/assets/img/type-code.png") top center no-repeat;
}
#peekmonInfo.typenerd {
  background: url("@/assets/img/type-nerd.png") top center no-repeat;
}
#peekmonInfo.type3d {
  background: url("@/assets/img/type-3d.png") top center no-repeat;
}
#peekmonInfo.typekunst {
  background: url("@/assets/img/type-kunst.png") top center no-repeat;
}
#peekmonInfo.typegame {
  background: url("@/assets/img/type-game.png") top center no-repeat;
}
#peekmonInfo.typemusic {
  background: url("@/assets/img/type-music.png") top center no-repeat;
}
#peekmonInfo.typefun {
  background: url("@/assets/img/type-fun.png") top center no-repeat;
}
#peekmonInventory {
  background: #222;
}
.inventoryMessage {
  --paddingSize: 12px;
  background: white;
  display: inline-block;
  padding: var(--paddingSize);
  width: calc(100% - (var(--paddingSize) * 2));
  color: black;
}
/*TODO gefangene peekmon liste schöner machen */
#peekmonInventory ul {
  margin: 10px 0px;

  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: normal;
  align-content: space-around;
  overflow: auto;
  padding-inline: 0px;
}
#peekmonSpecs {
  padding: 0px 5px;
  width: calc(50% - 10px);
  float: right;
}
#peekmonInfo .spec {
  text-align: center;
  display: inline-block;
  width: calc(100% / 2 - 12px);
}
.mobileView #peekmonInfo .spec {
  min-width: 100%;
}
#peekmonInfo .spec label {
  float: left;
  font-size: 0.7em;
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 15px;
}
.spec span {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 3vmin;
  padding: 5px 12px;
  text-align: center;
}
#peekmonInfo .spec meter {
  height: 1.5em;
  width: 100%;

  border-radius: 0px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 3vmin;
  padding: 5px 3px;
}

.desktopView #peekmonDetails {
  position: fixed;
  top: calc(50% + 35px);

  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);

  backdrop-filter: blur(7px);
  width: 600px;
  overflow: auto;
  border-radius: 20px 20px 0 0;
}
.mobileView #peekmonDetails {
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgba(0, 255, 0, 0.95);
  background: green;
  width: 100%;
  height: calc(100% - 70px);
  overflow: auto;
}

.name {
  font-size: 2em;
  text-align: center;
  line-height: 3em;
}

.image {
  float: left;
  width: 50%;
  height: auto;
  aspect-ratio: 1/1;
  background-size: cover;
}
</style>