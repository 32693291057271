<template>
  <div class="wizard">
    <tabList v-model="activeStep" :steps="steps" />
    <configSteps>
      <stepTab
        name="welcome"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        <h2>Willkommen!!1!11eins</h2>
        <div>Ich bin der BongoCat wizard</div>
      </stepTab>
      <stepTab
        name="channelname"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
      <p>Bitte gib hier deinen Twitch-Usernamen ein.<br /></p>
        <inputText
          label="Dein Twitch Username"
          v-model="paramList.channel.value"
        />
      </stepTab>
      <stepTab name="volume" :activeStep="Object.entries(steps)[activeStep][0]">
        <inputSlider
          label="Lautstärke"
          v-model="paramList.volume.value"
          min="0.1"
          max="1"
          step="0.1"
        />
      </stepTab>
      <stepTab name="notes" :activeStep="Object.entries(steps)[activeStep][0]">
        <inputSlider
          label="Mindestgeschwindigkeit in BPM"
          v-model="paramList.minBpm.value"
          min="20"
          max="100"
          step="10"
        />
        <inputSlider
          label="Maximalgeschwindigkeit in BPM"
          v-model="paramList.maxBpm.value"
          min="100"
          max="1000"
          step="20"
        />
        <inputSlider
          label="Maximal parallel spielbare Noten"
          v-model="paramList.maxNotesPerBatch.value"
          min="1"
          max="15"
          step="1"
        />
      </stepTab>

      <stepTab name="finish" :activeStep="Object.entries(steps)[activeStep][0]">
        So, fertsch. Haste gut gemacht. Jetzt nimm die URL hier und lege eine
        neue Browserquelle in OBS an. Stelle die Breite auf 1920 und die Höhe auf 1080 ein. Trage im Feld URL bitte die kopierte Adresse ein.<br />
        
        <urlBar :url="url" />
      </stepTab>
      <stepTab
        name="information"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
      <p>Hier findest du einige Beispiele für Songs. Eine genaue Anleitung folgt. <small>irgendwann</small></p>
      <p><pre>!bongo ,310 v5 . . . 5 . . . 4 3 . 2 . v5 v7 2 v5 . . . 5 . . . 4 3 . . 2 . . . 1 . . . ^1 . . . 6# 6 . 5 . 1 3 5 1 . . . ^1 . . . 6# 6 . . 5 . 5 6 v57 . 7 . 5 . . . 4 3 . 2 . v57 v76 25 v52 . 2 . 5 . . . 4 3 . . 2 . 5 6 v57 . 7 . 57 6 5 2 4 3 2 2 . v52 v75 26 v57 . 7 . 57 6 5 ^2 43 32 ^2 . 2^2 . ^2 ^3 ^4 . ^4 . ^4 ^3 ^2 ^1 . . ^1 . 5 6 6# 6 . . . ^3 . ^6</pre></p>
      <p>Dir und deinen Moderatoren stehen folgende Befehle zur Verfügung:<br />
        <ul>
          <li>
          <pre>!disableBongo</pre> deaktiviert die Katze</li>
          <li>

            <pre>!enableBongo</pre> aktiviert die Katze
          </li>
          <li>
            <pre>!bongoVolume X</pre> stellt die Lautstärke der Katze auf X. (0 bis 1, z. B. 0.6)
          </li>
          <li>
            <pre>!bongoClear</pre> leert die Wiedergabeliste
          </li>
          <li>
            <pre>!bongoSkip</pre> überspringt den aktuellen Song
          </li>
        </ul>
      </p>
      <p>Für reguläre Nutzer stehen die folgenden Befehle zur Verfügung:<br />
        <ul>
          <li>
            <pre>!bongo (noten)</pre> spielt die angegebenen Noten mit der modernen Notation. Siehe Beispiele</li>
          <li>
            <pre>!bongol (noten)</pre> spielt die angegebenen Noten mit der klassischen Notation. Für alte Songs.
          </li>
          <li>
            <pre>!bpm X</pre> stellt die Abspielgeschwindigkeit der Songs für den Benutzer ein.
          </li>
          <li>
            <pre>!bongoplay (songname)</pre> spielt einen vorgefertigten Song aus der Sammlung ab.
          </li>
        </ul>
      </p>
      </stepTab>
    </configSteps>
    <prevNextButtons v-model="activeStep" :maxPages="Object.entries(steps).length" />
  </div>
</template>
<script setup>
// store für userdaten anlegen
// einzelne ui komponenten anlegen
// template schreiben
// bindings setzen (ref() kram)
import { ref, computed, watch } from "vue";
import inputText from "./ui/inputText.vue";
import inputSlider from "./ui/inputSlider.vue";
import prevNextButtons from "./ui/prevNextButtons.vue";
import urlBar from "./ui/urlBar.vue";
import stepTab from "./ui/stepTab.vue";
import tabList from "./ui/tabList.vue";
import previewWindow from "./ui/previewWindow.vue";
import wizardStyle from "./ui/wizardStyle.vue";
import configSteps from "./ui/configSteps.vue";
const debug = false;

const activeStep = ref(0);

const steps = {
  welcome: "Willkommen",
  channelname: "Kanalname",
  volume: "Lautstärke",
  notes: "Noten",
  finish: "In OBS einbinden",
  information: "Zusätzliche Infos",
};

const paramDefaults = {
  channel: "jvpeek",
  volume: 1,
  minBpm: 50,
  maxBpm: 800,
  maxNotesPerBatch: 5,
};

const paramList = {
  channel: ref(paramDefaults.channel),
  volume: ref(0.7),
  minBpm: ref(50),
  maxBpm: ref(800),
  maxNotesPerBatch: ref(5),
};
const baseURL = "https://jvpeek.de/ext/bc/?";
const url = ref(baseURL);
watch(
  () => Object.entries(paramList),
  (newVal, oldVal) => {
    recalculateParams();
  },
  { deep: true }
);

const totalSteps = computed(() => {
  return parseInt(
    (
      (paramList.maxVotes.value - paramList.minVotes.value) /
        paramList.stepSize.value +
      1
    ).toFixed(0),
    10
  );
});
function recalculateParams() {
  let newParams = new URLSearchParams();
  for (const [key, value] of Object.entries(paramList)) {
    if (paramList[key].value != paramDefaults[key] || debug) {
      if (Array.isArray(paramList[key].value)) {
        newParams.append(
          key,
          paramList[key].value.slice(0, totalSteps.value || 0).join("|")
        );
        continue;
      }
      newParams.append(key, paramList[key].value);
    }
  }
  url.value = baseURL + newParams.toString();
}
recalculateParams();
</script>
<style scoped>
</style>