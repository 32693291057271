<template>
  <label :for="randomId">{{ label }}</label>
  <div>
    <input
      type="range"
      :min="min"
      :max="max"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :step="step"
      
    />
    <span>{{ modelValue }}{{ unit }}</span>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps(["label", "min", "max", "step", "modelValue", "unit"]);
defineEmits(["update:modelValue"]);

const randomId = "id" + String(Math.floor(Math.random() * 2000000));
</script>
<style scoped>
div {
  display: grid;
  grid-template-columns: 10fr 1fr;
  text-align: right;
  padding-bottom: 1em;
}
label {
  text-align: right;
  padding-right: 1em;
  
}
input {
  width: calc(90% + 10%);
  display: block;
  border: none;
  margin: 0px;
  
}
input:focus {
  background: rgba(255,255,255,.1);
}
</style>