<template>
  <div class="tabList">
    <div
      v-for="(tab, i) in tabList"
      :key="i"
      :class="
        'tabTitle ' +
        (i < modelValue ? 'done' : 'todo') +
        (i == modelValue ? ' active' : '')
      "
      @click="$emit('update:modelValue', i)"
    >
      {{ tab[1] }}
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, computed } from "vue";

const props = defineProps(["steps", "modelValue"]);
defineEmits(["update:modelValue"]);
const tabList = computed(() => {
  return Object.entries(props.steps);
});
</script>
<style scoped>
.tabList {
     display: flex;
     flex-direction: row;
     flex-wrap: nowarp;
     justify-content: center;
     align-items: center;
     align-content: center;
     width: 100%;
     background: rgba(0,0,0,0.5);
     margin-top: 20px;
     padding: 8px 0;
     border-top: solid 1px black;
     border-bottom: solid 1px black;

}
.mobileView .tabList {
    flex-direction: column;
    flex-wrap: wrap;
}

.tabTitle {
     flex: 1 1 auto;
     text-align: center;
     width: 100%;
}

.active {
  font-weight: 900;
}
.done {
  color: rgba(255, 255, 255, 0.5);
}
</style>