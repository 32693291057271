import { defineStore } from 'pinia'
import { ofetch } from 'ofetch'

export const useDocsStore = defineStore('docs', {
    state: () => ({ docsTopics: []}),
    actions: {
        async getData(topic) {
            this.docsTopics = []
            const res = await fetch(`/c/assets/json/` + topic +`.json`)
            this.docsTopics = await res.json()
        }
    }
})
