<template>
  
  <ul class="index">
    <li v-for="entry in props.datensatz" :key="entry.key">
      <a  @click="scroll('topic' + entry.key)">{{ entry.name }}</a>
      <ul>
        <li v-for="subentry in entry.entries" :key="subentry.key">
          <a @click="scroll('entry' + subentry.key)">{{ subentry.name }}</a>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script setup>
import { defineProps } from "vue";

function scroll(element) {
  element = document.getElementById(element);
  window.scrollTo(element.offsetLeft, element.offsetTop-75);
}
const props = defineProps({
  datensatz: Object,
});

</script>
<style scoped>
a {
  cursor: pointer;
  text-decoration: none;
}
.index {
  margin: 10px auto;
  background: rgba(0,0,0,0.5);
  max-width: 800px;
  padding: 10px 20px;
}
li {
  list-style-type: none;
}
</style>