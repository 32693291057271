import { defineStore } from 'pinia'

// You can name the return value of `defineStore()` anything you want, 
// but it's best to use the name of the store and surround it with `use` 
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useStatusStore = defineStore('status', {
    state: () => ({ loginStatus: {status: "unknown"}, bodyScroll: [], subPage: "peekmonList", wallpaper: "assets/wallpapers/default.jpg" }),
    getters: {
        isLoggedIn() {
            return this.$state.loginStatus.status === "ok";
        }
    },
    actions: {
        async getLoginStatus() {
            
            fetch(`https://jvpeek.de/c/api/isLoggedIn.php`).then(resp => resp.json()).then(json => { 
                // Object.entries(json).forEach((element) => {
                //     //console.log(element[1]);
                //     if (this.loginStatus[element[0]] != element[1]) {
                //         this.loginStatus[element[0]] = element[1];
                //     }
                // }); 
                this.loginStatus = json;
            });
        },
        registerBodyScroll(element) {
            //console.log("registering element " + element);
            this.bodyScroll.push(element);
            //console.log(JSON.stringify(this.bodyScroll))
        },
        unregisterBodyScroll(element) {

            this.bodyScroll = this.bodyScroll.filter((item) => item != element);

        }
    }
})