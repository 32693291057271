<template>
  <div :class="'loginButton' + (store.loginStatus.mod ? ' moderator':'')">
    <a
      v-if="store.loginStatus.status == 'unauthorized'"
      :href="store.loginStatus.loginLink"
    >
      <span>Login with Twitch</span>
      <span><img src="@/assets/img/login-round.png" /> </span
    ></a>

    <a title="Logout" v-if="store.loginStatus.status == 'ok'" @click="logout()"
      ><span>{{ store.loginStatus.displayName }} </span>
      <span><img :src="store.loginStatus.picture" /></span>
    </a>
  </div>
</template>
<script setup>
import { useStatusStore } from "@/store/status.js";

const store = useStatusStore();
async function fetchData() {
  store.getLoginStatus();
}
async function logout() {
  console.log("tschö");
  fetch("auth/logout.php");
  store.getLoginStatus();
}

fetchData();
</script>

<style scoped>
.loginButton {
  display: flex;
  justify-content: flex-end;
}
.loginButton a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  display: flex;
  align-items: center;
  align-content: center;
  color: white;
  text-decoration: none;
}

.loginButton a span {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  padding: 3px 5px;
}

.loginButton a img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.loginButton a img {
  border: 2px solid white;
}
.moderator a img {
  border: 2px solid yellow;
}
</style>