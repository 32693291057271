<template>
  <inlineLoginButton loginText="Log dich mal ein, Brudi!" />

  <div
    v-if="(status.isLoggedIn && !status.loginStatus.sub) || true"
    class="warning"
  >
    Uffbasse: Du bist aktuell kein Subscriber. Du kannst zwar einen Klingelton
    hochladen, er wird aber erst dann aktiv, wenn du ein Abo abschließt.<br /><br />
    Klick
    <a href="https://www.twitch.tv/products/jvpeek" target="_blank">hier</a>, um
    das zu ändern.
  </div>
  <div class="settings" v-if="!status.isLoggedIn">
    <h2>Klingelton</h2>
    <p>Klingeltonstatus: {{ status.loginStatus.ringtoneStatus }}</p>
    <p v-if="hasRingtone">Klingelton: <audio src="https://jvpeek.de/ext/sb/sounds/jvpeek/200puls.mp3" controls></audio></p>
    <p>Neuer Klingelton: <input type="file" /></p>
    
  </div>
</template>
<script setup>
import { useStatusStore } from "@/store/status";
import { computed, ref } from "vue";
import inlineLoginButton from "../ui/inlineLoginButton.vue";
const hasRingtone = ref(true);
const status = useStatusStore();
</script>
<style scoped>
.warning {
  max-width: 800px;
  margin: auto;
  background: rgba(255, 255, 255, 1);
  color: black;
  border: 2px solid black;
  border-radius: 8px;
  padding: 12px 8px;
}
pre {
  background: rgba(0, 0, 0, 0.5);
}
</style>