<template>
  <div class="imageContainer">
    <img src="@/assets/img/uc.gif" />
   
  </div>
</template>
<style scoped>
.imageContainer {
  width: 100%;
  height: 100%;
}
.imageContainer img {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>