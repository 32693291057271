<template>
    <div class="loginButton">
    <a
      v-if="store.loginStatus.status == 'unauthorized'"
      :href="store.loginStatus.loginLink"
    >
      <span>{{ loginText }}</span><br />
      <span><img src="@/assets/img/login-round.png" /> </span
    ></a>
  </div>
</template>

<script setup>

import { useStatusStore } from "@/store/status.js";

import { defineProps } from 'vue';
const props = defineProps({
  loginText: String,
});
const store = useStatusStore();
</script>

<style scoped>
.loginButton {
    text-align: center;
    
}
a {
    margin: 20px auto;
    display: inline-block;
    padding: 10px 17px;
    border-radius: 15px;
    background: rgba(255,255,255,1);
    
    color: black;
    text-decoration: none;
}
</style>