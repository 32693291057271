<template>
  <div class="wizard">
    <tabList v-model="activeStep" :steps="steps" />
    <configSteps>
      <stepTab
        name="welcome"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        <h2>Willkommen!!1!11eins</h2>
        <div>Ich bin der Zitate wizard</div>
      </stepTab>

      <stepTab
        name="channelname"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        <p>Bitte gib hier deinen Twitch-Usernamen ein.<br /></p>
        <inputText
          label="Dein Twitch Username"
          v-model="paramList.channel.value"
        />
      </stepTab>
      <stepTab
        name="timings"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        <inputSlider
          label="Dauer der Einblendung"
          v-model="paramList.timeout.value"
          min="5"
          max="60"
          step="1"
        />

        <inputSlider
          label="Abstand zwischen den Einblendungen"
          v-model="paramList.gap.value"
          min="1"
          max="60"
          step="1"
        />
      </stepTab>
      <stepTab name="rights" :activeStep="Object.entries(steps)[activeStep][0]">
        <inputDropDown
          label="Auslösen über"
          v-model="paramList.accessMethod.value"
          :options="{
            reward: 'Kanalpunkte',
            command: 'Chatbefehl',
          }"
        />
        <inputText
          v-if="paramList.accessMethod.value == 'reward'"
          label="Reward ID"
          v-model="paramList.rewardID.value"
        />

        <inputDropDown
          label="Erlaubt für"
          v-model="paramList.everyone.value"
          :options="{
            true: 'Jeder',
            false: 'Nur Moderatoren',
          }"
        />
      </stepTab>

      <stepTab name="finish" :activeStep="Object.entries(steps)[activeStep][0]">
        So, fertsch. Haste gut gemacht. Jetzt nimm die URL hier und lege eine
        neue Browserquelle in OBS an. Stelle die Breite auf 1920 und die Höhe
        auf 1080 ein. Trage im Feld URL bitte die kopierte Adresse ein.<br />
        <br />
        <urlBar :url="url" />
      </stepTab>
    </configSteps>
    <prevNextButtons
      v-model="activeStep"
      :maxPages="Object.entries(steps).length"
    />
  </div>
</template>
<script setup>
// store für userdaten anlegen
// einzelne ui komponenten anlegen
// template schreiben
// bindings setzen (ref() kram)
import { ref, computed, watch } from "vue";
import inputText from "./ui/inputText.vue";
import inputSlider from "./ui/inputSlider.vue";
import prevNextButtons from "./ui/prevNextButtons.vue";
import urlBar from "./ui/urlBar.vue";
import stepTab from "./ui/stepTab.vue";
import tabList from "./ui/tabList.vue";
import inputDropDown from "./ui/inputDropDown.vue";
import wizardStyle from "./ui/wizardStyle.vue";
import configSteps from "./ui/configSteps.vue";
const debug = false;

const activeStep = ref(0);

const steps = {
  welcome: "Willkommen",
  channelname: "Kanalname",
  timings: "Zeiten",
  rights: "Zugriffsarten",
  finish: "In OBS einbinden",
};

const paramDefaults = {
  channel: "jvpeek",
  timeout: 10,
  gap: 1,
  rewardID: "",
  everyone: false,
  accessMethod: "command",
};

const paramList = {
  channel: ref(paramDefaults.channel),
  timeout: ref(10),
  gap: ref(1),
  rewardID: ref(""),
  everyone: ref(false),
  accessMethod: ref("command"),
};
const baseURL = "https://jvpeek.de/ext/quote/?";
const url = ref(baseURL);
console.log(paramList);
watch(
  () => Object.entries(paramList),
  (newVal, oldVal) => {
    recalculateParams();
  },
  { deep: true }
);

const totalSteps = computed(() => {
  return parseInt(
    (
      (paramList.maxVotes.value - paramList.minVotes.value) /
        paramList.stepSize.value +
      1
    ).toFixed(0),
    10
  );
});

function recalculateParams() {
  console.log("recalculating parameters");
  let newParams = new URLSearchParams();
  for (const [key, value] of Object.entries(paramList)) {
    if (paramList[key].value != paramDefaults[key] || debug) {
      newParams.append(key, paramList[key].value);
    }
  }
  url.value = baseURL + newParams.toString();
}
recalculateParams();
</script>
<style scoped>
</style>
  