<template>
  <div class="wizard">
    <tabList v-model="activeStep" :steps="steps" />
    <configSteps>
      <stepTab
        name="welcome"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
        <h2>Willkommen!!1!11eins</h2>
        <div>Ich bin der Hub wizard</div>
      </stepTab>

      <stepTab
        name="channelname"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >
      <p>Bitte gib hier deinen Twitch-Usernamen ein.<br /></p>
        <inputText
          label="Dein Twitch Username"
          v-model="paramList.channel.value"
        />
      </stepTab>
      <stepTab
        name="display"
        :activeStep="Object.entries(steps)[activeStep][0]"
      >

      <inputSlider
          label="maximale Zeichen"
          v-model="paramList.maxChars.value"
          min="1"
          max="20"
          step="1"
        />

        <inputSlider
          label="Dauer der Einblendung"
          v-model="paramList.timeout.value"
          min="10"
          max="600"
          step="1"
        />

        <inputDropDown
      label="Einblenderichtung"
      v-model="paramList.direction.value"
      :options="{
        left: 'Links',
        right: 'Rechts'
      }"
      
    />
      </stepTab>
     

      <stepTab name="finish" :activeStep="Object.entries(steps)[activeStep][0]">
        So, fertsch. Haste gut gemacht. Jetzt nimm die URL hier und lege eine
        neue Browserquelle in OBS an. Stelle die Breite auf 1920 und die Höhe
        auf 1080 ein. Trage im Feld URL bitte die kopierte Adresse ein.<br />
        <br />
        <urlBar :url="url" />
      </stepTab>
    </configSteps>
    <prevNextButtons
      v-model="activeStep"
      :maxPages="Object.entries(steps).length"
    />
  </div>
</template>
<script setup>
// store für userdaten anlegen
// einzelne ui komponenten anlegen
// template schreiben
// bindings setzen (ref() kram)
import { ref, computed, watch } from "vue";
import inputText from "./ui/inputText.vue";
import inputSlider from "./ui/inputSlider.vue";
import prevNextButtons from "./ui/prevNextButtons.vue";
import urlBar from "./ui/urlBar.vue";
import stepTab from "./ui/stepTab.vue";
import tabList from "./ui/tabList.vue";
import inputDropDown from "./ui/inputDropDown.vue";
import wizardStyle from "./ui/wizardStyle.vue";
import configSteps from "./ui/configSteps.vue";
const debug = false;

const activeStep = ref(0);

const steps = {
  welcome: "Willkommen",
  channelname: "Kanalname",
  display: "Anzeige",
  finish: "In OBS einbinden",
};

const paramDefaults = {
  channel: "jvpeek",
  timeout: 300,
  maxChars: 5,
  direction: "left",
};

const paramList = {
  channel: ref(paramDefaults.channel),
  timeout: ref(300),
  maxChars: ref(5),
  direction: ref("left"),
};
const baseURL = "https://jvpeek.de/ext/ph/?";
const url = ref(baseURL);
console.log(paramList);
watch(
  () => Object.entries(paramList),
  (newVal, oldVal) => {
    recalculateParams();
  },
  { deep: true }
);


function recalculateParams() {
  console.log("recalculating parameters");
  let newParams = new URLSearchParams();
  for (const [key, value] of Object.entries(paramList)) {
    if (paramList[key].value != paramDefaults[key] || debug) {
      
      newParams.append(key, paramList[key].value);
    }
  }
  url.value = baseURL + newParams.toString();
}
recalculateParams();
</script>
<style scoped>
</style>
  