<template>
  <div class="peekmon" :class="(datensatz.shinycount>0 ? 'shiny ' : '') + (datensatz.counter > 0 ? 'checkmark' : '')" @click="$emit('changeDetailView', datensatz.userid)">
    <img class="picture" :src="datensatz.image" />

    <span class="nametag">{{ datensatz.peekdexNum }} {{ nameTag }}</span>

    <span v-if="datensatz.shinycount > 0" class="icons">
      <starIcon
    /></span>
    <span v-else-if="datensatz.counter > 0" class="icons"
      ><checkmarkIcon
    /></span>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

import starIcon from "/src/components/ui/icons/starIcon.vue";

import checkmarkIcon from "/src/components/ui/icons/checkmarkIcon.vue";
const props = defineProps({
  datensatz: Object,
  loggedIn: Object,
});
const nameTag = ref(
  props.datensatz.username.length > 23
    ? props.datensatz.username.substr(0, 20) + "..."
    : props.datensatz.username
);
</script>


<style scoped>

.peekmon {
  cursor: pointer;
}
.desktopView .peekmon {
  position: relative;
  align-content: start;
  aspect-ratio: 1/1;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  overflow: hidden;
}
.desktopView .peekmon .picture {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0px;
  left: 0px;
}

.desktopView .peekmon .icons {
  line-height: normal;
  position: absolute;
  left: 5px;
  top: 5px;
}
.desktopView .peekmon .nametag {
  position: absolute;
  bottom: 5%;
  background: rgba(0, 0, 0, 0.2);
  width: calc(100% - 10px);
  backdrop-filter: blur(5px);
  display: inline-block;
  overflow: hidden;
  line-height: 1.2em;
  padding: 5px;
}
.mobileView .peekmon {
  border-bottom: 1px rgba(0, 0, 0, 0.5) solid;
  line-height: 4em;
  font-size: 1em;
  vertical-align: middle;
  width: 100%;
  position: relative;
  background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(4em), rgba(0,0,0,.5) calc(4em), rgba(0,0,0,.5) 100%);
}
.mobileView .peekmon .picture {
  float: left;
  padding-right: 5px;
  height: 4em;
  width: auto;
}
.mobileView .peekmon .icons {
  position: absolute;
  right: 2em;
  width: 1em;
  height: auto;
  aspect-ratio: 1/1;
  line-height: normal;
  top: 50%;
  transform: translate(0,-50%);  
}

</style>