<template>
  <inlineLoginButton loginText="Log dich mal ein, Brudi!" />

  <div
    class="winBar"
    v-show="status.loginStatus.status != 'unauthorized' && bingo.solved"
  >
    <span>
      Du hast eine Reihe vervollständigt.<br />Du kannst jetzt dein Sheet
      einreichen.</span
    >
    <button
      :key="clicked"
      @click="
        copyToClipboard(
          'https://jvpeek.de/c/api/viewBingo.php?seed=' +
            bingo.bingoBase.myBoard[12].long
        )
      "
      id="bingoShareButton"
      class="buttonAnimation"
    >
      Link kopieren
    </button>
    <button @click="bingo.submitData()">Einreichen</button>
  </div>

  <div id="bingoCards" v-if="status.loginStatus.status != 'unauthorized'">
    <div
      v-for="(entry, index) in bingo.bingoBase.myBoard"
      :key="index"
      :title="(entry.key || 'Seed') + ': ' + entry.long"
      :class="
        (entry.requested ? 'requested' : '') +
        ' ' +
        (entry.verified ? 'verified' : '')
      "
      :id="entry.key === undefined ? 'middle' : ''"
      class="bingoCard"
      @click="bingo.setData(entry, index)"
    >
      <img
        v-if="entry.key === undefined"
        class="profilePicture"
        :src="status.loginStatus.picture"
      />
      <span class="bingoLabel">
        {{ entry.short }}
      </span>
    </div>
  </div>

  <br /><br />

  <table>
    <tr
      v-for="(entry, index) in bingo.bingoBase.board"
      :key="index"
      :title="entry.long"
    >
      <td>{{ entry.key }}</td>
      <td>{{ entry.short }}</td>
      <td>{{ entry.long }}</td>
    </tr>
  </table>
</template>
<script setup>
import { useBingoStore } from "@/store/bingo";
import { useStatusStore } from "@/store/status";
import { computed, ref } from "vue";
import inlineLoginButton from "../ui/inlineLoginButton.vue";
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification();

const status = useStatusStore();
const bingo = useBingoStore();
const clicked = ref(false);
function copyToClipboard(value) {
  navigator.clipboard.writeText(value);

  notify({
    text: "In die Zwischenablage kopiert",
    type: "normal",
    duration: 10000,
  });
}
bingo.getData();
</script>
<style scoped>
.winBar {
  margin-top: 20px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  color: white;
  text-align: center;
  font-size: 1em;
  display: flex;
  justify-content: center;
  gap: 2em;
  padding: 10px 0;
}

.winBar span {
  display: inline-block;
}
.winBar button {
  background: rgba(255, 255, 255, 0.9);
  color: black;
  padding: 5px 8px;
  border: none;
  font-size: 1.2em;
  border-radius: 6px;
}
#bingoCards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  margin: auto;
  height: calc(100vmin - 94px);

  width: calc(100vmin - 94px);
  padding: 10px;
}

.bingoCard {
  overflow: hidden;
  aspect-ratio: 1 / 1;
  text-align: center;
  display: inline-block;
  border-radius: 8px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid black;

  transition: background-color 1s;
  position: relative;
}
.bingoCard .profilePicture {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  filter: brightness(0.4);
}
.bingoLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  word-break: break-word;
  transition: font-size 0.75s ease-in-out;
  width: 100%;
}
.bingoCard.requested {
  background-color: rgba(255, 255, 255, 0.7);
}
.bingoCard.verified {
  transition: background-color 1s;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: white;
}
.mobileView .bingoLabel {
  font-size: 0.6em;
  transition: font-size 0.75s ease-in-out;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
  height: -20px;
}
</style>